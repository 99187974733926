<script>
function getChartColorsArray(colors) {
    colors = JSON.parse(colors);
    return colors.map(function(value) {
        var newValue = value.replace(" ", "");
        if (newValue.indexOf(",") === -1) {
            var color = getComputedStyle(document.documentElement).getPropertyValue(newValue);
            if (color) {
                color = color.replace(" ", "");
                return color;
            } else return newValue;
        } else {
            var val = value.split(',');
            if (val.length == 2) {
                var rgbaColor = getComputedStyle(document.documentElement).getPropertyValue(val[0]);
                rgbaColor = "rgba(" + rgbaColor + "," + val[1] + ")";
                return rgbaColor;
            } else {
                return newValue;
            }
        }
    });
}
import SwiperCore from "swiper";

import "swiper/swiper-bundle.css";

SwiperCore.use([]);
import moment from "moment";
import axios from 'axios';

import "flatpickr/dist/flatpickr.css";
import {
    CountTo
} from "vue3-count-to";
import QuickLink from './components/QuickLinks.vue';
import Layout from "../../layouts/main.vue";
import PageHeader from "@/components/page-header";
import Swal from "sweetalert2";

//   import SalesLocation from "../dashboard/ecommerce/sales-location";

export default {
    components: {
        CountTo,
        Layout,
        QuickLink,
        PageHeader,


        //   Revenue,
        //   SalesLocation,
    },
    data() {
        return {
            link: process.env.VUE_APP_URL,
            linkref: "https://gopay.ng/",

            title: "Dashboard",
            referral: [],
            monthamount: 0,
            trans: [],
            items: [{
                    text: "Dashboards",
                    href: "/",
                },
                {
                    text: "Dashboard",
                    active: true,
                },
            ],
            date: null,
            config: {
                mode: "range",
            },
            series: [44, 55, 41, 17, 15],
            chartOptions: {
                labels: ["Direct", "Social", "Email", "Other", "Referrals"],
                chart: {
                    height: 333,
                    type: "donut",
                },
                legend: {
                    position: "bottom",
                },
                stroke: {
                    show: false,
                },
                dataLabels: {
                    dropShadow: {
                        enabled: false,
                    },
                },
                colors: getChartColorsArray('["--vz-primary", "--vz-success", "--vz-warning", "--vz-danger", "--vz-info"]'),
            },
        };
    },

    computed: {
        user() {
            return this.$store.getters['getuser'];
        },

        reflink() {
            return this.linkref + 'refer/' + this.user.username;
        },


        getmonthabv() {
            const date = new Date(Date.now());
            return date.toLocaleString('en-US', { month: 'short' });
        },

        goottext() {

            var ndate = new Date();
            var hours = ndate.getHours();
            var message = hours < 12 ? 'Good Morning' : hours < 18 ? 'Good Afternoon' : 'Good Evening';

            return message;


        }




    },
    methods: {

        deposit() {

            this.$router.push({name: "depositbank"});

        },

        copy() {

            this.$copyText(this.reflink)
                .then(() => {
                    this.onCopy();
                })
                .catch(() => {
                    this.onError();
                });

        },
        onCopy() {
            Swal.fire("Copied!", "Referral link copied", "success");

        },
        onError() {
            Swal.fire("Copied!", "Failed to copy link!", "error");

        },
        classcall(stataus) {

            if (stataus == 1) {
                return 'text-warning';

            } else if (stataus == 2) {
                return 'text-primary';

            } else if (stataus == 3) {
                return 'text-success';

            } else if (stataus == 0) {
                return 'text-danger';

            }






        },
        dateTime(value) {
            return moment(value).fromNow();
        },
        redirect(hash) {
            this.$router.push({ name: "transaction", params: { hash: hash } });

        },
        async gettrans() {

            this.load = true;
            await axios.get(process.env.VUE_APP_MAIN_URL + "/get/transactions").catch((error) => {

                this.load = false;

                throw error;

            }).then((response) => {
                this.load = false;
                if (response.data.status == true) {

                    this.trans = response.data.data.data;
                    if (response.data.data.data.length > 7) {

                        this.trans = response.data.data.data.slice(0, 7)
                        this.monthamount = response.data.data.amount;

                    }





                }

            })
        },
        async getref() {

            this.load = true;
            await axios.get(process.env.VUE_APP_MAIN_URL + "/get/referrals").catch((error) => {

                this.load = false;

                throw error;

            }).then((response) => {
                this.load = false;
                if (response.data.status == true) {

                    this.referral = response.data.data.data;
                    this.setPages();


                }

            })
        },



        rightcolumn() {
            if (document.querySelector('.layout-rightside-col').classList.contains('d-none')) {
                document.querySelector('.layout-rightside-col').classList.remove('d-none')
            } else {
                document.querySelector('.layout-rightside-col').classList.add('d-none')
            }
        }
    },
    created() {
        this.getref()
        this.gettrans()
    },
};
</script>

<template>
    <Layout>
        <PageHeader :title="title" :items="[]" />
        <div class="row">
            <div class="col">
                <div class="h-100">
                    <div class="row mb-3 pb-1">
                        <div class="col-12">
                            <div class="d-flex align-items-lg-center flex-lg-row flex-column">
                                <div class="flex-grow-1">
                                    <h4 class="fs-16 mb-1">{{goottext}}, {{user.name}}!</h4>
    
                                </div>
    
                            </div>
                            <!-- end card header -->
                        </div>
                        <!--end col-->
                    </div>
                    <!--end row-->
                    <Notification />
                    <div class="row">
                        <div class="card border card-border-primary">
                            <div class="card-header">
                                <h6 class="card-title mb-0"> Download Our App<span class="badge bg-danger align-middle fs-10">New</span></h6>
                            </div>
                            <div class="card-body">
                                <p class="card-text">Download Our app then drop a review on playstore or apple store and get  <span class="fw-bold">NGN 60</span> credited to your wallet.</p>
                                <p class="card-text">Once done , please chat us on live chat with your email to be credited.</p>
                                <div class="text-end"> <a href="https://apps.apple.com/us/app/gopay-bills/id1634302890" target="_blank"> <b-img class="mr-2 mt-1" width="140%" src="https://lordtech.com/wp-content/uploads/2021/07/app-store.png" /></a>
                                    <a href="https://play.google.com/store/apps/details?id=com.asapsoftwares.gopayng" target="_blank"> <b-img  width="140%" class="mr-2 mt-1" src="https://lordtech.com/wp-content/uploads/2021/07/play-store.png" /></a>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-12">
                            <div class="card">
                                <div class="card-header align-items-center d-flex">
                                    <h4 class="card-title mb-0 flex-grow-1">My Referral Link</h4>
    
                                </div>
                                <!-- end card header -->
                                <div class="card-body">
                                    <div class="live-preview">
                                        <div>
    
                                            <div class="row g-3">
    
    
                                                <div class="col-lg-12">
                                                    <div class="input-group">
                                                        <input type="text" class="form-control" aria-label="Recipient's username with two button addons" readonly :value="reflink">
                                                        <button class="btn btn-primary" type="button" @click="copy">Copy</button>
    
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
    
                                </div>
                            </div>
                        </div>
                        <!--end col-->
                    </div>
    
                    <div class="row">
                        <div class="col-xl-3 col-md-6">
                            <!-- card -->
                            <div class="card card-animate">
                                <div class="card-body">
                                    <div class="d-flex align-items-center">
                                        <div class="flex-grow-1 overflow-hidden">
                                            <p class="text-uppercase fw-medium text-muted text-truncate mb-0">
                                                My Balance
                                            </p>
                                        </div>
    
                                    </div>
                                    <div class="d-flex align-items-end justify-content-between mt-4">
                                        <div>
                                            <h4 class="fs-22 fw-semibold ff-secondary mb-4">
                                                ₦
                                                <count-to :startVal='0' :endVal='user.balance' :duration='5000'></count-to>
                                            </h4>
                                            <b-button @click="deposit()" variant="primary" size="sm">Deposit Now</b-button>
                                         
    
                                        </div>
                                        <div class="avatar-sm flex-shrink-0">
                                            <span class="avatar-title bg-soft-success rounded fs-3">
                                <i class="bx bx-dollar-circle text-success"></i>
                              </span>
                                        </div>
                                    </div>
                                </div>
                                <!-- end card body -->
                            </div>
                            <!-- end card -->
                        </div>
                        <!-- end col -->
    
                        <div class="col-xl-3 col-md-6">
                            <!-- card -->
                            <div class="card card-animate">
                                <div class="card-body">
                                    <div class="d-flex align-items-center">
                                        <div class="flex-grow-1 overflow-hidden">
                                            <p class="text-uppercase fw-medium text-muted text-truncate mb-0">
                                                Referral Commission
                                            </p>
                                        </div>
    
                                    </div>
                                    <div class="d-flex align-items-end justify-content-between mt-4">
                                        <div>
                                            <h4 class="fs-22 fw-semibold ff-secondary mb-4">
                                                ₦
                                                <count-to :startVal='0' :endVal='user.commission' :duration='5000'></count-to>
                                            </h4>
    
                                        </div>
                                        <div class="avatar-sm flex-shrink-0">
                                            <span class="avatar-title bg-soft-primary rounded fs-3">
                                <i class="bx bx-wallet text-primary"></i>
                              </span>
                                        </div>
                                    </div>
                                </div>
                                <!-- end card body -->
                            </div>
                            <!-- end card -->
                        </div>
                        <!-- end col -->
    
                        <div class="col-xl-3 col-md-6">
                            <!-- card -->
                            <div class="card card-animate">
                                <div class="card-body">
                                    <div class="d-flex align-items-center">
                                        <div class="flex-grow-1 overflow-hidden">
                                            <p class="text-uppercase fw-medium text-muted text-truncate mb-0">
                                                Total Referral
                                            </p>
                                        </div>
    
                                    </div>
                                    <div class="d-flex align-items-end justify-content-between mt-4">
                                        <div>
                                            <h4 class="fs-22 fw-semibold ff-secondary mb-4">
                                                <count-to :startVal='0' :endVal='this.referral.length' :duration='5000'></count-to>
    
                                            </h4>
    
                                        </div>
                                        <div class="avatar-sm flex-shrink-0">
                                            <span class="avatar-title bg-soft-warning rounded fs-3">
                                <i class="bx bx-user-circle text-warning"></i>
                              </span>
                                        </div>
                                    </div>
                                </div>
                                <!-- end card body -->
                            </div>
                            <!-- end card -->
                        </div>
                        <!-- end col -->
    
                        <div class="col-xl-3 col-md-6">
                            <!-- card -->
                            <div class="card card-animate">
                                <div class="card-body">
                                    <div class="d-flex align-items-center">
                                        <div class="flex-grow-1 overflow-hidden">
                                            <p class="text-uppercase fw-medium text-muted text-truncate mb-0">
                                                {{getmonthabv }} Transaction
                                            </p>
                                        </div>
    
                                    </div>
                                    <div class="d-flex align-items-end justify-content-between mt-4">
                                        <div>
                                            <h4 class="fs-22 fw-semibold ff-secondary mb-4">
                                                ₦
                                                <count-to :startVal='0' :endVal='monthamount' :duration='5000'></count-to>
                                            </h4>
    
                                        </div>
                                        <div class="avatar-sm flex-shrink-0">
                                            <span class="avatar-title bg-soft-primary rounded fs-3">
                                <i class="bx bx-wallet text-primary"></i>
                              </span>
                                        </div>
                                    </div>
                                </div>
                                <!-- end card body -->
                            </div>
                            <!-- end card -->
                        </div>
                        <!-- end col -->
                    </div>
                    <!-- end row-->
    
                    <div class="row">
    
                        <!-- end col -->
    
                        <div class="col-xl-4">
                            <div class="row">
                                <h3 class="card-title mb-3 flex-grow-1 "> Quick Links </h3>
                                <quick-link :link="'airtime'" name="Airtime" :icon="'bx-phone'"></quick-link>
                                <quick-link :link="'data'" name="Data" :icon="'bx-wifi-2'"></quick-link>
                                <quick-link :link="'electricity'" name="Electricity" :icon="'bxs-bolt'"></quick-link>
                                <quick-link :link="'cabletv'" name="CableTv" :icon="'bxs-tv'"></quick-link>
                                <quick-link :link="'showmax'" name="Showmax" :icon="'bx-laptop'"></quick-link>
                                <quick-link :link="'education'" name="Education" :icon="'bx-book-alt'"></quick-link>
    
                            </div>
                        </div>
    
    
                        <div class="col-xl-8">
                            <div class="card">
                                <div class="card-header align-items-center d-flex">
                                    <h4 class="card-title mb-0 flex-grow-1">Recent Transactions</h4>
    
                                </div>
                                <!-- end card header -->
    
                                <div class="card-body">
                                    <div class="table-responsive table-card">
                                        <table class="table table-borderless table-centered align-middle table-nowrap mb-0">
                                            <thead class="text-muted table-light">
                                                <tr>
                                                    <th scope="col">Order ID</th>
                                                    <th scope="col">Title</th>
                                                    <th scope="col">Recipient</th>
                                                    <th scope="col">Amount</th>
                                                    <th scope="col">Date</th>
                                                    <th scope="col">Status</th>
                                                    <th scope="col">Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(tran) in trans" :key="tran.id">
                                                    <td>
                                                        <router-link :to="{name: 'transaction', params: { hash: tran.hash}}" class="fw-medium link-primary">#{{tran.code}}
                                                        </router-link>
                                                    </td>
                                                    <td>
                                                        <div class="d-flex align-items-center">
                                                            <div class="flex-shrink-0 me-2">
                                                                <img :src="link+tran.image" alt="" class="avatar-xs rounded-circle" />
                                                            </div>
                                                            <div class="flex-grow-1">{{tran.title}}</div>
                                                        </div>
                                                    </td>
                                                    <td>{{tran.recipient}}</td>
                                                    <td>
                                                        <span :class="classcall(tran.status)">₦{{tran.amount_paid}}</span>
                                                    </td>
                                                    <td>{{dateTime(tran.created_at)}}</td>
                                                    <td>
                                                        <span class="badge badge-soft-warning" v-if="tran.status == 1">Pending</span>
                                                        <span class="badge badge-soft-success" v-if="tran.status == 3">Completed</span>
                                                        <span class="badge badge-soft-primary" v-if="tran.status == 2">Processing</span>
                                                        <span class="badge badge-soft-danger" v-if="tran.status == 0">Cancelled</span>
                                                    </td>
                                                    <td>
                                                        <span><b-button variant="primary" size="sm" @click="redirect(tran.hash)">view</b-button>
                            
                    
                            </span>
                                                    </td>
                                                </tr>
                                                <!-- end tr -->
    
                                                <!-- end tr -->
                                            </tbody>
                                            <!-- end tbody -->
                                        </table>
                                        <!-- end table -->
                                    </div>
                                </div>
                            </div>
                            <!-- .card-->
                        </div>
                        <!-- end col -->
                    </div>
    
    
                    <!-- end row-->
                </div>
                <!-- end .h-100-->
            </div>
            <!-- end col -->
    
    
            <!-- end col -->
        </div>
    </Layout>
</template>