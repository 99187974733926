<template>
     <div class="col-6">
    <router-link :to="{name: link}">
  <b-card class="text-center ">
  <div class="d-flex justify-content-center mb-3">
      <div class="avatar-sm " >
                      <span class="avatar-title bg-soft-primary rounded fs-3">
                        <i class="bx  text-primary" :class="icon"></i>
                 
                      </span>
                    </div>
  </div>
                     

    <div class="truncate">
      <h3 class="mb-25 font-weight-bolder">
   {{name}}
      </h3>

    </div>

  </b-card>
  

</router-link>
           </div>
</template>
<script>
export default {
    props: ['link', 'name', 'icon']
}
</script>